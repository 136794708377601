import * as React from "react";
import { motion } from "framer-motion";
import colors from "../../../constants/colors";
const HandDrawnOval = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            viewBox="0 0 1024 624"
            {...props}
        >
            <defs>
                <linearGradient id="Gradient1">
                    <stop offset="0%" stopColor={colors.gray6} />
                    <stop offset="100%" stopColor={colors.darkerLogoTeal} />
                </linearGradient>
            </defs>

            <motion.path
                fill="url(#Gradient1)"
                d="M402.9 72.007c-18.339 5.441-35.07 14.287-50.507 24.02-13.88 8.75-28.446 16.143-42.332 24.757-.677.42-1.242 1.022-1.674 1.926 12.797-4.409 24.633-11.275 38.158-15.298-.25 3.18-2.257 3.56-3.706 4.369-18.338 10.236-36.734 20.493-53.687 32.85-15.794 11.514-30.808 24.16-45.634 36.924-29.136 25.083-55.419 52.928-78.134 83.995-25 34.19-45.03 70.789-53.503 112.862-5.018 24.919-6.15 49.849-.168 74.735 8.207 34.15 28.395 59.406 58.423 76.989 24.134 14.132 50.445 21.207 78.205 23.635 39.448 3.45 78.012-2.654 116.401-10.449 69.571-14.126 137.966-32.658 205.117-55.707 59.923-20.569 118.735-43.902 175.526-72.07 25.752-12.771 49.996-27.974 71.346-47.481 25.54-23.335 42.316-51.475 45.625-86.286 2.271-23.9-2.124-47.108-11.44-69.308-14.385-34.277-36.72-62.094-67.813-82.567-21.886-14.411-46.431-22.248-71.86-27.567-35.796-7.487-71.969-8.403-108.348-6.278-62.706 3.661-122.838 18.722-181.551 40.33-9.206 3.388-18.663 6.093-28.008 9.1-.916.294-1.866.873-2.848-.062.484-2.274 2.678-2.232 4.217-2.876 36.325-15.192 73.87-26.678 111.84-36.91 26.2-7.059 53.045-11.336 79.242-18.217 24.711-6.491 49.521-4.693 74.414-3.947 6.668.2 13.293-.497 19.892-.421 10.455.12 20.905 1.306 31.296 2.804 18.325 2.643 36.105 7.736 54.005 12.176 9.36 2.322 17.652 7.841 25.692 13.207 50.033 33.394 80.665 79.69 90.203 139.11 7.308 45.534-5.805 85.56-35.961 120.235-24.396 28.051-54.946 47.85-87.594 64.515-55.558 28.36-113.19 51.977-171.87 73.04-46.553 16.71-93.8 31.079-141.7 43.558-32.73 8.527-65.834 14.919-98.852 21.957-22.918 4.886-45.974 7.667-69.26 8.138-43.363.877-84.94-6.406-122.384-29.988-15.642-9.85-28.676-22.464-40.685-36.58-22.487-26.433-29.32-57.582-31.507-91.059-3.708-56.758 16.039-106.246 46.364-152.154 51.678-78.234 121.554-135.936 205.336-177.085 25.594-12.57 52.358-22.398 80.189-29.004 1.967-.467 4.006-1.844 6.808-.32-5.533 3.218-11.503 4.03-17.272 6.402z"
            />
        </svg>
    );
};
export default HandDrawnOval;
