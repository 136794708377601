export default [
    {
        value: "Iphone 8",
    },
    {
        value: "Iphone X",
    },
    {
        value: "Iphone XR",
    },
    {
        value: "Iphone SE",
    },
    {
        value: "Iphone 12",
    },
    {
        value: "Iphone 13",
    },
    {
        value: "Iphone SE 2nd Generation",
    },
    {
        value: "Iphone 12 Pro",
    },
    {
        value: "Iphone 12 Mini",
    },
    {
        value: "Iphone 13 Pro",
    },
    {
        value: "Iphone 13 Mini",
    },
    {
        value: "Iphone SE 3rd Generation",
    },
    {
        value: "Iphone 14 Pro",
    },
    {
        value: "Iphone 14 Mini",
    },
    {
        value: "Iphone 14 Pro Max",
    },
    {
        value: "Iphone 15",
    },
    {
        value: "Iphone 15 Pro",
    },
    {
        value: "Iphone 15 Pro Max",
    },
    {
        value: "Airpods Max",
    },
    {
        value: "Airpod Pros",
    },
];
