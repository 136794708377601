export default {
    gray1: "#ffffff",
    gray2: "#fafafa",
    gray3: "#f5f5f5",
    gray3five: "#f3f3f3",
    gray4: "#f0f0f0",
    gray5: "#d9d9d9",
    gray6: "#bfbfbf",
    gray7: "#8c8c8c",
    gray8: "#595959",
    gray9: "#434343",
    gray10: "#262626",
    gray11: "#1f1f1f",
    gray12: "#141414",
    gray13: "#000000",
    black: "#000000",
    warning1: "#ff7a45",
    alert1: "#f5222d",
    alert2: "#fa541c",
    good: "#52c41a",
    lightGood: "#64E392",
    linkColor: "#1890ff",
    logoTeal: "#E6F7FF",
    darkerLogoTeal: "#CDEFFF",
    brandTeal: "#0ABAB4",
};
