import { Breadcrumb, Col, Row, Typography } from "antd";
import { Link, useParams } from "react-router-dom";

import { ArrowLeftOutlined } from "@ant-design/icons";
import ClaimCreationForm from "../../components/policies/claims/detail/create/ClaimCreationForm";
import ClaimCreationHelp from "../../components/policies/claims/detail/create/ClaimCreationHelp";
import ClaimListErrorBar from "../../components/policies/claims/detail/create/ClaimListErrorBar";
import { Policy } from "../../../redux/reducers/types/commonTypes";
import React from "react";
import { useAppSelector } from "../../../redux/hooks";

const { Title } = Typography;

export default function ClaimCreate() {
    const { id } = useParams();
    const policyId = parseInt(id || "");

    const policies: Policy[] = useAppSelector(
        (state) => state.policies.publicPolicies
    );
    const policy: Policy =
        policies.find((p: Policy) => p.id === policyId) || ({} as Policy);

    return (
        <div>
            <Row>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to={`/policy/${id}`}>
                            <ArrowLeftOutlined /> Back to policy
                        </Link>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Row>
            <Row>
                <Title level={4} style={{ marginTop: ".75rem" }}>
                    Create a claim
                </Title>
            </Row>
            <Row>
                <Col span={14}>
                    <ClaimListErrorBar />
                    <ClaimCreationForm policy={policy} />
                </Col>
                <Col span={8} offset={1}>
                    <ClaimCreationHelp />
                    {/* <div style={{ margin: "1.5rem 0" }}>
                        <div>maybe policy claims history?</div>
                        <div>claim likelehood?</div>
                    </div> */}
                </Col>
            </Row>
        </div>
    );
}
